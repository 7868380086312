import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

const app = createApp(App);

app.use(router)

app.config.globalProperties.$axios=axios

app.component("font-awesome-icon", FontAwesomeIcon);

app.mount('#app')
