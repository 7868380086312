
import { defineComponent } from "vue";
import MoviesList from "../components/MoviesList.vue";

export default defineComponent({
  name: "TopRatedMoviesView",

  components: {
    MoviesList,
  },

  data: () => ({
    pageTitle: "Top Rated",
  })
});
