import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import TopRatedMoviesView from '../views/TopRatedMoviesView.vue';
import MovieDetailsView from '../views/MovieDetailsView.vue';
import ActorDetailsView from '../views/ActorDetailsView.vue';
import NotFoundView from '../views/NotFoundView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/top-rated',
    name: 'top_rated',
    component: TopRatedMoviesView
  },
  {
    path: '/movie/:id',
    name: 'movie_details',
    component: MovieDetailsView
  },
  {
    path: '/actor/:id',
    name: 'actor_details',
    component: ActorDetailsView
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: NotFoundView,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
