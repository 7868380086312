import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-101d5fb5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MovieCard = _resolveComponent("MovieCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.movies, (movie) => {
      return (_openBlock(), _createElementBlock("div", {
        key: movie.id,
        class: "col-xs-12 col-sm-6 col-lg-4 col-xl-3"
      }, [
        _createVNode(_component_MovieCard, {
          movie: movie,
          genres: _ctx.genres,
          showRating: true
        }, null, 8, ["movie", "genres"])
      ]))
    }), 128))
  ]))
}