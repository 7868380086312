
import { defineComponent } from "vue";
import env from "../env";
import MovieCard from "./MovieCard.vue";
import { Movie } from "@/models/Movie";


export default defineComponent({
  name: "MoviesList",
  components: { MovieCard },

  props: {
    listType: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    searchTerm: "",
    movies: Array as unknown | Movie[],
    genres: [],
  }),

  mounted() {
    this.listMovies();
    this.getGenres();
  },

  methods: {
    listMovies() {
      this.$axios
        .get(
          `${env.api_url}/movie/${this.$props.listType}?api_key=${env.api_key}`
        )
        .then((response) => {
          this.movies = response.data.results;
        })
        .catch((err) => console.log(err));
    },

    getGenres() {
      this.$axios
        .get(`${env.api_url}/genre/movie/list?api_key=${env.api_key}`)
        .then((response) => {
          this.genres = response.data.genres;
        })
        .catch((err) => console.log(err));
    },
  },
});
