import moment from "moment";

export default {
	formatDateLong(value: any) {
		return moment(value).format("MMMM DD, YYYY");
	},

	formatDateShort(value: any) {
		return moment(value).format("MMM DD, YYYY");
	},

	yearsAgo(value: any) {
		return moment(value, "YYYYMMDD").fromNow(true);
	}
}