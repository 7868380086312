
import { defineComponent } from "vue";

export default defineComponent({
  name: "SearchItem",

  props: {
    movie: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    genericMoviePoster: require("../assets/generic-poster.png"),
  }),

  computed: {
    moviePoster() {
      return !this.movie["poster_path"]
        ? this.genericMoviePoster
        : `https://image.tmdb.org/t/p/w500/${this.movie["poster_path"]}`;
    },
  },
});
