import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f0b1d4de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "thumbnail" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "card-content" }
const _hoisted_4 = { class: "card-title" }
const _hoisted_5 = { class: "card-desc" }
const _hoisted_6 = ["title"]
const _hoisted_7 = { class: "card-footer" }
const _hoisted_8 = { class: "m-0 release" }
const _hoisted_9 = {
  key: 0,
  class: "m-0 pt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MovieGenre = _resolveComponent("MovieGenre")!

  return (_openBlock(), _createElementBlock("div", {
    class: "movie card",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDetails(_ctx.movie.id)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.movieCardImage,
        alt: _ctx.movie.title,
        class: "img-fluid"
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.movie.title), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.movie.overview), 1),
      (_ctx.showRating)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            title: `Score: ${Number(_ctx.movie?.vote_average).toFixed(2)}`,
            class: "score"
          }, _toDisplayString(Number(_ctx.movie?.vote_average).toFixed(2)), 9, _hoisted_6))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("p", _hoisted_8, "Release date: " + _toDisplayString(_ctx.formatDateShort(_ctx.movie.release_date)), 1),
      (_ctx.movieGenres)
        ? (_openBlock(), _createElementBlock("p", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.movieGenres, (genre) => {
              return (_openBlock(), _createBlock(_component_MovieGenre, {
                genre: genre,
                key: genre.id
              }, null, 8, ["genre"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}