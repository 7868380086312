import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b90f82c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "card-subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: `/actor/${_ctx.actor.id}`,
    class: "card shadow-sm"
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        src: _ctx.actorImage,
        alt: _ctx.actor['name'],
        class: "img-fluid"
      }, null, 8, _hoisted_1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.actor["name"]), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.actor["character"]), 1)
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}