
import { defineComponent } from 'vue';
import formatDateShort from '../utils/datetime-formatter';
import router from '@/router';
import MovieGenre from "./MovieGenre.vue";

export default defineComponent({
  name: 'MovieCard',

   components: {
    MovieGenre
  },

  props: {
    movie: {
      type: Object,
      required: true,
    },

    genres: {
      type: Object,
      required: false,
    },

    showRating: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return formatDateShort
  },

  data: () => ({
    genericCardImage: require('../assets/generic-card-image.png'),
  }),

  methods: {
    showDetails(movie_id: any) {
      let movie_url = `/movie/${movie_id}`
      router.push(movie_url);
    },
  },

  computed: {
    movieCardImage() {
      return !this.movie?.backdrop_path
        ? this.genericCardImage
        : `https://image.tmdb.org/t/p/w500/${this.movie?.backdrop_path}`
    },

    movieGenres() {
      if (this.genres) {
        return this.genres.filter((genre: any) => this.movie?.genre_ids.includes(genre.id))
      } else {
        return []
      }
    },
  },
})
