import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5afe6de4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "movie-poster" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "movie-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.moviePoster,
        alt: _ctx.movie.title
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.movie.title), 1)
  ], 64))
}