import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c3ab696"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "trailersCarousel",
  class: "carousel slide",
  "data-bs-interval": "false"
}
const _hoisted_2 = {
  key: 0,
  class: "carousel-indicators"
}
const _hoisted_3 = ["data-bs-slide-to"]
const _hoisted_4 = { class: "carousel-inner" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.movieTrailers.length > 1)
      ? (_openBlock(), _createElementBlock("ol", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.movieTrailers.slice(0, _ctx.maxCarouselItems), (video, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: video.id,
              "data-bs-target": "#trailersCarousel",
              "data-bs-slide-to": `${index}`,
              class: _normalizeClass({ active: index === 0 })
            }, _toDisplayString(index + 1), 11, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.movieTrailers.slice(0, _ctx.maxCarouselItems), (video, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: video.id,
          class: _normalizeClass(["carousel-item", { active: index === 0 }])
        }, [
          _createElementVNode("iframe", {
            class: "embed-responsive-item",
            src: `https://www.youtube.com/embed/${video.key}`
          }, null, 8, _hoisted_5)
        ], 2))
      }), 128))
    ])
  ]))
}