
  import { defineComponent } from 'vue';
  import MovieDetails from '../components/MovieDetails.vue';

  export default defineComponent({
    name: 'MovieDetailsView',

    components: {
      MovieDetails
    }

  });
