
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import env from "../env";
import formatDateLong from "../utils/datetime-formatter";
import { Actor } from "../models/Actor";
import MovieCard from "./MovieCard.vue";
import router from "@/router";

export default defineComponent({
  name: "ActorDetails",
	components: { MovieCard },

  data: () => ({
    route: useRoute(),
    genericActorPhoto: require("../assets/generic-actor.png"),
    actor: null as Actor | null,
    knownFor: [],
  }),

  setup() {
    return formatDateLong
  },

  mounted() {
    this.getActorDetails();
  },

  methods: {
    getActorDetails() {
      this.$axios
        .get(
          `${env.api_url}/person/${this.route.params.id}?api_key=${env.api_key}`
        )
        .then((response) => {
          this.actor = response.data;
					// Once we have the actor data, get his/her movies too
					this.getActorMovies();
        })
        .catch((err) => console.log(err));
    },

    getActorMovies() {
      this.$axios
        .get(
          `${env.api_url}/person/${this.route.params.id}/combined_credits?api_key=${env.api_key}`
        )
        .then((response) => {
          this.knownFor = response.data.cast.slice(0, 12); // Show 12 movies by this actor
        })
        .catch((err) => console.log(err));
    },
  },

  computed: {
    actorImage() {
      return !this.actor?.profile_path
        ? this.genericActorPhoto
        : `https://image.tmdb.org/t/p/w500/${this.actor?.profile_path}`;
    },
  },
});
