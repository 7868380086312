
  import { defineComponent } from 'vue';
  import ActorDetails from '../components/ActorDetails.vue';

  export default defineComponent({
    name: 'ActorDetailsView',

    components: {
      ActorDetails
    }

  });
