
import { defineComponent } from "vue";

export default defineComponent({
  name: "ActorCard",

  props: {
    actor: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    genericActorPhoto: require("../assets/generic-actor.png"),
  }),

  computed: {
    actorImage() {
      return !this.actor?.profile_path
        ? this.genericActorPhoto
        : `https://image.tmdb.org/t/p/w500/${this.actor?.profile_path}`;
    },
  },
});
