
	import { defineComponent } from 'vue';

	export default defineComponent({
		name: 'MovieGenre',

    props: {
      genre: {
        type: Object,
        required: false,
      }
    }
	});
  
