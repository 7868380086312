
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import env from "../env";
import { Movie } from "@/models/Movie";
import MovieGenre from "./MovieGenre.vue";
import TrailerCarousel from "./TrailerCarousel.vue";
import ActorCard from "./ActorCard.vue";
import router from "@/router";

export default defineComponent({
  name: "MovieDetails",

  components: {
    MovieGenre,
    TrailerCarousel,
    ActorCard,
  },

  data: () => ({
    route: useRoute(),
    genericPosterBig: require('../assets/generic-poster.png'),
    movie: null as Movie | null,
    movieQuality: String as unknown | 'bad',
    movieTrailers: [],
    movieCast: [],
  }),

  mounted() {
    this.getMovieDetails();
    this.getMovieTrailers();
    this.getMovieCast();
  },

  methods: {
    getMovieDetails() {
      this.$axios
        .get(
          `${env.api_url}/movie/${this.route.params.id}?api_key=${env.api_key}`
        )
        .then((response) => {
          this.movie = response.data;
          // Once you get the movie, set its quality
          this.setMovieQuality();
        })
        .catch((err) => console.log(err));
    },

    setMovieQuality() {
      if (Number(this.movie?.vote_average) >= 7) {
         this.movieQuality = 'good';
      } else if(Number(this.movie?.vote_average) < 7 && Number(this.movie?.vote_average) > 5.5) {
         this.movieQuality =  'average';
      } else {
         this.movieQuality =  'bad';
      }
    },

    getMovieTrailers() {
      this.$axios
        .get(
          `${env.api_url}/movie/${this.route.params.id}/videos?api_key=${env.api_key}`
        )
        .then((response) => {
          this.movieTrailers = response.data.results;
        })
        .catch((err) => console.log(err));
    },

    getMovieCast() {
      this.$axios
        .get(
          `${env.api_url}/movie/${this.route.params.id}/credits?api_key=${env.api_key}`
        )
        .then((response) => {
          this.movieCast = response.data.cast;
        })
        .catch((err) => console.log(err));
    },
  },

  computed: {
    genericPoster() {
      return !this.movie?.poster_path
        ? this.genericPosterBig
        : `https://image.tmdb.org/t/p/w500/${this.movie?.poster_path}`
    },
  },

  watch: {
    "$route.params.id"() {
      this.getMovieDetails();
      this.getMovieTrailers();
      this.getMovieCast();
    },
  },
});
