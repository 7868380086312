
	import { defineComponent, ref } from 'vue';
  import env from '../env';
  import SearchItem from './SearchItem.vue';

	export default defineComponent({
		name: 'TopBar',

    components: {SearchItem},

    data: () => ({
        searchForm: ref(null),
        isSearch: false,
        searchTerm: '',
        timeOutInterval: 1000,
        movies: []
      }),

    mounted() {
      this.windowEvents();
    },

    methods: {
      windowEvents() {
        // Check for click outside the search form
        window.addEventListener('click', (event) => {
          if (!(this.$refs.searchForm as HTMLFormElement).contains(event.target as Node|null)) {
              this.isSearch = false;
          }
        });
      },

      debounceMovieSearch() {
        setTimeout(this.doMovieSearch, this.timeOutInterval)
      },

      doMovieSearch() {
        if (this.searchTerm.length > 2) {
            this.isSearch = true;
            this.$axios.get(`${env.api_url}/search/movie?api_key=${env.api_key}&query=${this.searchTerm}`).then(response => {
            this.movies = response.data.results;
          })
          .catch(err => console.log(err));
        } else {
          this.isSearch = false;
        }
      },
    }
	});
