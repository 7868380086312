
import { defineComponent } from "vue";
export default defineComponent({
  name: "TrailerCarousel",

  props: {
    movieTrailers: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    maxCarouselItems: 5,
  }),
});
