
  import { defineComponent } from 'vue';

  // Import components
  import TopBar from './components/TopBar.vue';
  import AppFooter from './components/AppFooter.vue';

  export default defineComponent({
    // Register components
    components: {
      TopBar,
      AppFooter
    }
  });
